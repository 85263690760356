module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-158823669-1","anonymize":true},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DobryBruk.pl | Kostka Brukowa IWONA LEGNER","short_name":"DobryBruk","start_url":"/","background_color":"#005526","theme_color":"#005526","display":"minimal-ui","icon":"src/assets/images/logoGreen.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
